<template>
    <hb-modal size="medium" :title="title" v-model="dialog" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            {{message}}
            <br /><br />
            <span v-if="type == 'delete'">This action cannot be undone.</span>
            <span v-else>Are you sure you want to continue?</span>
          </div>
        </template>
        <template v-slot:right-actions>
          <hb-btn v-if="type == 'delete'" color="destructive" @click="$emit('delete-plan')">Delete</hb-btn>
          <hb-btn v-if="type == 'defaultOnline'" color="primary" @click="$emit('online-default-plan')">Continue</hb-btn>
          <hb-btn v-if="type == 'defaultInstore'" color="primary" @click="$emit('instore-default-plan')">Continue</hb-btn>
          <hb-btn v-if="type == 'editPlan'" color="primary" @click="$emit('edit-plan')">Continue</hb-btn>
        </template>
    </hb-modal>
</template>
<script>
export default {
    name: 'ConfirmationPopUp',
    props: ['value','title', 'message', 'type'],
    computed:{
        dialog: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    },
}
</script>