<template>
    <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }">
        <div ref="rmTopSection">
            <hb-header :divider="false" full>
                <hb-page-header title="Advanced Reservation and Rental Plan Library"
                    description="This section is where users can create, manage, and view different advanced reservation and rental plans.">
                </hb-page-header>
            </hb-header>
            <MenuTabs :menuOptions="menuOptions"></MenuTabs>
            <v-expansion-panels multiple>
                <hb-expansion-panel guidance>
                    <template v-slot:title>
                        Cut-Off Time
                    </template>
                    <template v-slot:content>
                        <div class="pa-4">
                            Please ensure to check settings of cut-off time, as it will affect the reservation windows and limit the reservations today based on the set time. To check or update the cut-off time settings, go to
                            <span class="hb-font-body-medium">Settings > Lead Management > Cut-Off Time for Reservation Window.</span>
                        </div>
                    </template>
                </hb-expansion-panel>
            </v-expansion-panels>
            <div class="pt-6">
                <div class="hb-font-body-medium">Advanced Reservation and Rental Plan Library</div>
                <div class="hb-font-body hb-text-light pt-3">The Advanced Reservation and Rental Plan Library allows company-wide management of reservation and rental plans. You can create plans to control how far in advance users can reserve or rent, assign them to properties, and set a default plan for all space groups. Deleting a plan removes it from assigned groups, and a default plan will replace it if available.</div>
            </div>
            <div>
                <hb-btn class="button-container" color="secondary" @click="showPlan" small>Add New Plan</hb-btn>
            </div>
            <apw-create-plan v-model="showCreatePlan" @plan-save-success="getAllPlans"></apw-create-plan>
            <v-row>
                <v-col v-for="plan in plans" cols="6">
                    <ApwCard style="height: 480px;"  :plan="plan" @plan-changed="getAllPlans"></ApwCard>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script type="text/babel">
import api from '../../assets/api';
import MenuTabs from '../settings/Common/MenuTabs.vue';
import ApwCard from './ApwCard.vue';
import ApwCreatePlan from './ApwCreatePlan.vue';

export default {
    name: "AdvancedPurchaseWindow",
    components: {
        MenuTabs,
        ApwCard,
        ApwCreatePlan
    },
    data() {
        return {
            showCreatePlan: false,
            plans: [],
            menuOptions: [
                {
                key: "corporate",
                label: "Corporate Settings",
                }
            ]
        };
    },
    async created() {
        await this.getAllPlans();
    },
    computed: {
        
    },
    methods: {
        showPlan() {
            this.showCreatePlan = true;
        },
        async getAllPlans() {
            try {
                this.plans = await api.get(this, api.APW_MANAGEMENT + 'plans')
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}
</style>